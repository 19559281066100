(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("KEditor"), require("jQuery"), require("CKEDITOR"));
	else if(typeof define === 'function' && define.amd)
		define(["KEditor", "jQuery", "CKEDITOR"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("KEditor"), require("jQuery"), require("CKEDITOR")) : factory(root["KEditor"], root["jQuery"], root["CKEDITOR"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__) {
return 